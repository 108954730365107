import { defineStore } from 'pinia'

type AppStoreState = {
  ready: boolean
  showHeader: boolean
  showNav: boolean
}

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => ({
    ready: false,
    showHeader: true,
    showNav: true,
  }),
})
